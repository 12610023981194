<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout mt-4">
				<v-col cols="12">
					<v-row dense>
						<v-col cols="10">
							<pui-text-area
								:id="`description-alert`"
								v-model="model.description"
								:label="$t('form.alert.description')"
								:disabled="!isCreatingElement"
								required
								toplabel
								maxlength="1000"
							></pui-text-area>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="4">
							<v-checkbox
								:id="`read-alert`"
								:value="model.read"
								v-model="model.read"
								:disabled="formDisabled"
								:label="$t('form.alert.read')"
							/>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns :formDisabled="formDisabled" :saveDisabled="saving" :save="save" :back="back"></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import alertActions from './AlertActions';

export default {
	name: 'alert-form',
	mixins: [PuiFormMethodsMixin],
	components: {},
	data() {
		return {
			modelName: 'alert',
			actions: alertActions.formactions
		};
	},
	methods: {
		afterGetData() {}
	},
	computed: {},
	created() {}
};
</script>
